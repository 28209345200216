// MODULES IMPORT //
import lazySizes from 'lazysizes'; // Lazysizes
// import GLightbox from 'glightbox'; // Lightbox
import Plyr from 'plyr'; // Plyr
import Drift from 'drift-zoom'; // DriftZoom
import tippy from 'tippy.js'; // Tippy
import Colcade from 'colcade'; // Colcade
// import * as LottiePlayer from '@lottiefiles/lottie-player'; // Lottie

// Swiper //
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

// BOOTSTRAP //
// import * as bootstrap from 'bootstrap'
import { Dropdown, Collapse, Modal, Tab, Offcanvas } from 'bootstrap';

// CUSTOM IMPORT //
import './custom/megamenu'; // Megamenu with BS tabs
import './custom/hoverintent'; // Hover intent
import './custom/autohide'; // Autohide
// import './custom/contain'; // elements.contain function
// import './custom/formfloater'; // Float form CF7+BS

// Variabili //
let scrollPosition = window.scrollY;
let body = document.querySelector('body');
let header = document.querySelector('header');
let headerH = header.clientHeight;

// Smooth Scroll //
const links = document.querySelectorAll('.smooth');

for (const link of links) {
    link.addEventListener('click', smoothFn);
}

function smoothFn(e) {
    e.preventDefault();
    const href = this.getAttribute('href');
    let headerHeight = document.querySelector('.header').clientHeight;
    const offsetTop = document.querySelector(href).offsetTop - headerHeight;
    scroll({
        top: offsetTop,
        behavior: 'smooth'
    });
}

// DriftZoom //
const zooms = document.querySelectorAll('.zoom');

if (zooms) {
    for (const zoom of zooms) {
        let render = zoom.querySelector('[data-zoom]');
        let zoomContainer = zoom.querySelector('.zoom-panel');
        new Drift(render, {
            paneContainer: zoomContainer,
            inlinePane: false,
            handleTouch: false,
            hoverBoundingBox: true,
            zoomFactor: 3,
        });
    }
}

// Tippy - Tooltips //
tippy('[data-tippy-content]');

// Masonry Gallery //
let walls = document.querySelectorAll('.masonry');

if (walls) {
    walls.forEach(wall => {

        var colc = new Colcade(wall, {
            columns: '.masonry-col',
            items: '.masonry-tile'
        });
    });
}

// Focus su campo di ricerca //
const searchPanel = document.getElementById('search-panel')
searchPanel.addEventListener('shown.bs.collapse', function () {
    searchPanel.querySelector('.form-control').focus();
})

// Video Player //
const vid_player = Plyr.setup('.video-player', {
    controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen'
    ],
    youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        modestbranding: 1
    },
    vimeo: {
        byline: false,
        portrait: false,
        title: false,
        speed: true,
        transparent: false
    }
});

// Autoplay video nei modal
let videoModals = document.querySelectorAll('.video-modal');
if (videoModals) {
    videoModals.forEach(modal => {
        // Pause all videos on hide
        modal.addEventListener('hidden.bs.modal', event => {
            vid_player.forEach(video => {
                video.pause();
            });
        });
        // Play this video on show
        modal.addEventListener('shown.bs.modal', event => {
            vid_player.forEach(video => {
                video.pause();
                if (video.elements.container.dataset.video == modal.id) {
                    video.play();
                }
            });
        });
    });
}

// Homepage Slider //
let homepage_slides = new Swiper('.homepage-slides', {
    loop: true,
    spaceBetween: 0,
    // allowTouchMove: false,
    speed: 1000,
    autoplay: {
        delay: 8000,
    },
    pagination: {
        el: '.slides-pagination',
        type: 'bullets',
        clickable: true,
        bulletClass: 'bullet',
        bulletActiveClass: 'active'
    },
});

// Product Slider //
let productGallery = new Swiper('.product-gallery', {
    autoHeight: false,
    loop: true,
    watchOverflow: true,
    speed: 500,
    slidesPerView: 1,
    navigation: {
        nextEl: '.product-nav.next',
        prevEl: '.product-nav.prev',
    },
    pagination: {
        el: '.product-pagination',
        type: 'fraction',
    },
});

// Related Slider //
let relatedSlider = new Swiper('.related-slider', {
    spaceBetween: 32,
    autoHeight: false,
    loop: false,
    watchOverflow: true,
    speed: 500,
    centerInsufficientSlides: true,
    slidesPerGroup: 2,
    navigation: {
        nextEl: '.related-nav.next',
        prevEl: '.related-nav.prev',
    },
    breakpoints: {
        0: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        },
        1600: {
            slidesPerView: 5,
        }
    }
});

// Collection Slider //
let collectionGallery = new Swiper('.collection-gallery', {
    autoHeight: false,
    loop: true,
    watchOverflow: true,
    speed: 500,
    slidesPerView: 1,
    navigation: {
        nextEl: '.collection-nav.next',
        prevEl: '.collection-nav.prev',
    },
    pagination: {
        el: '.collection-pagination',
        clickable: true,
    },
});

// Gallery Slider //
let gallerySlider = new Swiper('.gallery-slider', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    loop: true,
    navigation: {
        nextEl: '.gallery-nav.next',
        prevEl: '.gallery-nav.prev',
    }
});


// Mantieni tab corrente
let pillsTab = document.querySelector('#accountTab');
if (pillsTab) {

    let pills = pillsTab.querySelectorAll('.tab-link');

    pills.forEach(pill => {
        pill.addEventListener('shown.bs.tab', (event) => {
            let {
                target
            } = event;
            let {
                id: targetId
            } = target;
            savePillId(targetId);
        });
    });

    let savePillId = (selector) => {
        localStorage.setItem('activePillId', selector);
    };

    let getPillId = () => {
        let activePillId = localStorage.getItem('activePillId');
        // if local storage item is null, show default tab
        if (!activePillId) return;
        // call 'show' function
        let someTabTriggerEl = document.querySelector(`#${activePillId}`)
        let tab = new Tab(someTabTriggerEl);
        tab.show();
    };

    // get pill id on load
    getPillId();

}

// Riposiziona Accordion //
let accordionWrapper = document.querySelector('.accordion.autoscroll');

if (accordionWrapper) {
    let accordionItems = accordionWrapper.querySelectorAll('.accordion-collapse');

    accordionItems.forEach((el) => {
        el.addEventListener('shown.bs.collapse', (e) => {
            let headerHeight = document.querySelector('.header').clientHeight;
            let scrollOffset = accordionWrapper.scrollTop + el.parentNode.offsetTop - headerHeight;
            // console.log(scrollOffset);
            window.scroll({
                top: scrollOffset,
                left: 0,
                behavior: 'smooth'
            });
        })
    });

}

// Custom two column resizer
let sections = document.querySelectorAll('.block-two-columns.with-bg');

function checkSize() {
    sections.forEach(section => {
        let media = section.querySelector('.media');
        let content = section.querySelector('.content');

        media.classList.remove('forced-cover');

        if (media.clientHeight < content.clientHeight) {
            if (media.classList.contains('media-contain')) {
                media.classList.add('forced-cover');
            }
        }
    });
}

window.addEventListener('resize', checkSize);
checkSize();

// HOVER INTENT //

// get top-level items from a dropdown menu
var menuItems = document.querySelectorAll('.nav-item');

// Touch device recognition
function isTouchDevice() {
    return true == ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch);
}

if (isTouchDevice() === true) {
    // console.log('Touch Device');
    menuItems.forEach(function (it) {
        it.classList.add('touch-device');
    });
} else {
    // console.log('Not a Touch Device');

    // set actions for mouse over and mouse leave
    var hi = new BZN.HoverIntent(menuItems, {
        // required parameters
        onEnter: function (targetItem) {
            targetItem.classList.add('visible');
        },
        onExit: function (targetItem) {
            targetItem.classList.remove('visible');
        },

        // default options
        exitDelay: 400,
        interval: 100,
        sensitivity: 7,
    });

}

// POPUP
const popupEl = document.querySelector('#popup');

// Verifica se è stata memorizzata l'informazione sull'ultima visualizzazione del popup
const lastPopupShown = localStorage.getItem('lastPopupShown');
const currentTime = new Date().getTime();
const hoursPassed = (currentTime - parseInt(lastPopupShown)) / (1000 * 60 * 60);

if (!lastPopupShown || hoursPassed >= 12) {
    if (popupEl) {
        let popupTimeout = popupEl.dataset.timeout;
        const popup = new Modal(popupEl);

        // Memorizza il timestamp corrente nello storage locale
        localStorage.setItem('lastPopupShown', currentTime);

        setTimeout(() => {
            popup.show();
        }, popupTimeout);
    }
}

// BIZEN //
document.addEventListener('DOMContentLoaded', function () {
    console.log('A DIGITAL PROJECT BY');
    console.log('oooo                                                                                                 \n\roooo                      oooo                                                                       \n\roooo                      oooo                                                                       \n\roooo                                                                                                 \n\roooo                                                                                                 \n\roooo    oooooo            oooo      oooooooooooooooo         ooooooooo               oooooooooo      \n\roooooooooooooooooo        oooo     ooooooooooooooooo      ooooooooooooooo         oooooooooooooooo   \n\roooooooo      ooooo       oooo                ooooo     ooooo         ooooo     ooooo          ooooo \n\rooooo           ooooo     oooo              ooooo      ooooo           ooooo   oooo             oooo \n\roooo             oooo     oooo             ooooo       ooooooooooooooooooooo   oooo              oooo\n\roooo             oooo     oooo           ooooo         oooo                    oooo              oooo\n\roooo             oooo     oooo          oooo           oooo                    oooo              oooo\n\r ooooo         ooooo      oooo        ooooo             oooo           oooo    oooo     Zeno     oooo\n\r   oooooooooooooooo       oooo      ooooooooooooooo      oooooo      ooooo     oooo      was     oooo\n\r      oooooooooo          oooo     ooooooooooooooooo       ooooooooooooo       oooo     here!    oooo\n\r');
});